import { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../Hooks/useUser";
import { conexionAPI_PostToken } from "../api/consultaAPI";
import  "../css/Login.css"
import md5 from "md5";

export default function CambiarPassword()
{
    const {isLogged,login,isLoading,UserName,logout}=useUser();
    const [loading, setLoading]=useState(false);
    const [codigos,setCodigos]=useState([]);
    const navigate=useNavigate()
    const [user,setUser]=useState({Username:"",Password:""});

    const handleSubmit=(e)=>{
        e.preventDefault();
        const nuevoUser={...user,["Password"]:md5(user.Password)}
        login(nuevoUser)

        if(!isLogged)
        {
            navigate("/Eventos")
        }
        }
    const handleChange= (e)=>{
        setUser({
                ...user,
                [e.target.name]:e.target.value
            });
    }

useEffect(()=>{
    if(!isLogged)
      navigate("/Login");
  },[isLogged])

return (<div className="containerPrincipal">
{isLoading?
<div><p>Cargando!!</p></div>:
<div className="containerSecundario">
    <form onSubmit={handleSubmit}>
    <div className="form-group">
        <label>Nueva Contraseña: </label>
        <br/>
        <input
                type="password"
        name="Username"
        onChange={handleChange}
        className="form-control"
        />
        <br/>
        <label>Repetir Contraseña: </label>
        <br/>
        <input
        type="password"
        name="Password"
        onChange={handleChange}
        className="form-control"
        />
        <br/>
        <button className="btn btn-primary">Guardar</button>
    </div>
    </form>
</div>}
</div>)
}
