import {
    BrowserRouter,
    Routes,
    Route,
    useHistory,
    Link,
    Navigate,
    HashRouter
  } from "react-router-dom";
import CambiarPassword from "../page/CambiarPassword";
import Eventos from "../page/Eventos";
import Codigos from "../page/Codigos";
import Login from "../page/Login";
import Pasarela from "../page/PasarelaPago";
import Inicio from "../page/Inicio"

export default function App() {
    return (  
      <HashRouter forceRefresh={true}>
      <Routes>
        <Route path="*" element={<Navigate replace to="/Inicio"/>}/>
        <Route path="Login" element={<Login/>}/>
        <Route path="CambiarPassword" element={<CambiarPassword/>}/>
        <Route path="Eventos" element={<Eventos/>}/>
        <Route path="Pasarela" element={<Pasarela/>}/>
        <Route path="Inicio" element={<Inicio/>}/>
        <Route path="Codigos/:idEvento" element={<Codigos/>}/>
        <Route exact path="/:codUnico" element={<Inicio/>}/>

        </Routes>
      </HashRouter>
    );
  }

