import Routes from "./src/routes/Routes"
import Notificacion from "./src/componentes/Notificacion";

import  {UserContextProvider} from "./src/Context/UserContext.js"
import { View } from "react-native";

export default function App() {

  return (
    <UserContextProvider>
    <div>
      <div className="Notificacion position-fixed float-end">
      <Notificacion className="MensajeError" mensaje=""></Notificacion>
      </div><div>
      <Routes/>
      </div>
    </div>
    </UserContextProvider>
  );
}
