import { CulqiProvider, Culqi } from 'react-culqi';
import { useEffect } from "react";
import useUser from '../Hooks/useUser';
import { useNavigate } from 'react-router-dom';

export default function Pasarela()
{
  const {isLogged,login,logout}=useUser();

  const handleSubmit=(e)=>{
    logout();
    }

    const navigate=useNavigate()
    useEffect(()=>{
      if(!isLogged) navigate('/Login')
    },[isLogged])


    return(
        <div style={{marginTop:"100px"}}>
          <button onClick={handleSubmit}>Cerrar Sesion</button>
        <CulqiProvider
        publicKey="pk_test_0e816e153360d87e"
        amount={10000}
        title="Arena Eventos"
        description="02 entrada(s) al evento 10/09/2022"
        onToken={token => {
        }}
        onError={error => {
          console.error("something bad happened", error);
        }}
        // Uncomment `options` to see customizations take place
        options={{
          style: {
            maincolor: "#007ACC",
            buttontext: "white",
            maintext: "black",
            desctext: "#252526",
            logo: "//placekitten.com/400/400"
          }
        }}
      >
        <div>
          <Culqi>
            {({ openCulqi, setAmount, amount }) => {
              return (
                <div>
                  <h3>Amount to pay: {amount}</h3>
                  <button onClick={openCulqi}>PAGAR</button>
                </div>
              );
            }}
          </Culqi>
        </div>
      </CulqiProvider></div>
    );
}