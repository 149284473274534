import { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import { useLocation, useNavigate,useParams } from "react-router-dom";
import useUser from "../Hooks/useUser";
import { conexionAPI_PostToken } from "../api/consultaAPI";
import Moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css"

export default function Codigos()
{
    const {isLogged,logout}=useUser();
    const [loading, setLoading]=useState(false);
    const [codigos,setCodigos]=useState([]);
    const navigate=useNavigate()
    let { idEvento } = useParams();
    function VerCodigos(){
        setLoading(true);

        conexionAPI_PostToken("ListarCodigos?id="+idEvento,idEvento).then(p => {
        if (p.correcto) {
           setCodigos(p.datos);
        }
        else {
            setCodigos([]);
        } 
    }).catch(e => { setCodigos([]);})
    setLoading(false)
}
useEffect(()=>{VerCodigos();},[])

useEffect(()=>{
    if(!isLogged)
      navigate("/Login");
  },[isLogged])

const handleSubmit=(e)=>{
    logout();
    }
return (<><div className="Cabecera position-fixed">
<button type="button" onClick={()=>{navigate("/Eventos")}} class="btn btn-link link-light">Eventos</button>
<button type="button" onClick={handleSubmit} class="btn btn-link link-light">Cerrar Sesión</button>
</div><table class="table table-hover" style={{marginTop:"50px"}}>
  <thead>
    <tr>
    <th scope="col">Codigo</th>
    <th scope="col">Tipo Entrada</th>
      <th scope="col">Fecha Venta</th>
      <th scope="col">Cliente</th>
      <th scope="col">Fecha Uso</th>
      
      
    </tr>
  </thead>
  <tbody>
    {codigos.map(p=>
    <tr>
      <td><span style={(Moment(p.FechaVenta)>Moment("2020/01/01"))?{color:"red", fontWeight:"bold"}:{color:"black"}}>{p.Codigo}</span></td>
      <td>{p.TipoEntrada}</td>
      <td>{(Moment(p.FechaVenta)>Moment("2020/01/01"))?Moment(p.FechaVenta).format("DD/MM/yyyy HH:mm:ss a"):""}</td>
      <td>{p.Cliente}</td>
      <td>{p.Usado?Moment(p.FechaUso).format("DD/MM/yyyy HH:mm:ss a"):"NO USADO"}</td>
      
      
    </tr>)
}
  </tbody>
</table></>)
}
